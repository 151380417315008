
import signupCarousel from '~/components/Molecules/OrganicSquad/SignupCarousel/SignupCarousel.vue'
import signupArguments from '~/components/Molecules/OrganicSquad/SignupArguments/SignupArguments.vue'
import { mapActions } from 'vuex'
import device from '~/mixins/device.js'

export default {
  mixins: [device],

  components: {
    signupIframe: () => import('~/components/Molecules/OrganicSquad/SignupIframe/SignupIframe.vue'),
    signupCarousel,
    signupArguments,
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    embed: Object,
    content: Object,
    testArguments: Object,
    gtm: Object,
  },

  data() {
    return {
      dataLayer: {},
      device: null,
      showFooter: true,
    }
  },

  methods: {
    ...mapActions('modalSignup', ['setSignupModalOpen']),

    closeModal() {
      document.body.classList.toggle('modal-open')
      this.setSignupModalOpen(false)

      this.$gtm.push({
        event: 'custom_event',
        custom_event_name: 'close_modal_sign_up_embed',
      })
    },
  },

  computed: {
    isTestModalArgumentsPT() {
      if (['pt-br'].includes(this.$i18n.locale) && this.isDesktop) {
        return this.$growthbook.isOn('9_corporate_website_home_desktop_brazil_modal_sidebar')
      }

      return false
    },

    isTestLoginActivatedPT() {
      if (['pt-br'].includes(this.$i18n.locale) && this.isPaidUser) {
        return this.$growthbook.isOn(
          '8_corporate_website_interested_producer_all_devices_brazil_login_social_x_without_login_social'
        )
      }

      if (['es', 'es-co', 'es-mx'].includes(this.$i18n.locale)) {
        return true
      }

      return false
    },

    isPaidUser() {
      return this.$route.query.utm_campaign === 'bra_br_awar_tra_institucional_search_hotmart'
    },

    hasTest() {
      return true
    },

    locale() {
      return this.$i18n.locale
    },

    route() {
      return this.$route
    },

    hasContent() {
      return Boolean(this.content)
    },

    images() {
      return this.content.images.length > 0 ? this.content.images : []
    },

    getTitleClass() {
      return !this.isTestLoginActivatedPT ? 'signup-modal--titleTest' : 'signup-modal--title'
    },

    getSubtitleClass() {
      return !this.isTestLoginActivatedPT ? 'signup-modal--subtitleTest' : 'signup-modal--subtitle'
    },

    getSubtitleContent() {
      return !this.isTestLoginActivatedPT ? this.embed.subtitleTest : this.embed.subtitle
    },
  },
}
