export default {
  props: {
    images: {
      type: Array,
      default: [],
    },

    legend: {
      type: String,
    },
  },

  data() {
    return {
      intervalID: null,
      isTransitioning: false,
      showID: 0,
      startTime: null,
      duration: 5000,
      isAnimating: true,
    }
  },

  mounted() {
    this.startTime = performance.now()
    this.animate()
  },

  beforeDestroy() {
    cancelAnimationFrame(this.intervalID)
  },

  methods: {
    animate(timestamp) {
      if (!this.startTime) this.startTime = timestamp
      const elapsedTime = timestamp - this.startTime

      const progress = parseFloat((elapsedTime / this.duration).toFixed(2))

      if (progress < 1 && this.isAnimating) {
        this.intervalID = requestAnimationFrame(this.animate)
      } else {
        this.startTime = timestamp
        this.updateImages(progress)
        this.intervalID = requestAnimationFrame(this.animate)
      }
    },

    async updateImages() {
      this.showID++
      if (this.showID >= this.lengthImages) {
        this.showID = 0
      }
    },
  },

  computed: {
    lengthImages() {
      return this.images.length
    },
  },
}
