export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    title: String,
    testimonial: Object,
  },
}
